import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const userAPIUrl = process.env.REACT_APP_AUTH_API_URL;

export const addUser = createAsyncThunk("user/AddUser", async (userData,{ rejectWithValue }) => {
  userData["status"] = 1;
  try {
    const response = await axios.post(
      `${userAPIUrl}/auth/user/signup`,
      userData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (err) {
    if (err.response) {
        return rejectWithValue({
            status: err.response.status,
            message: err.response.data.message || err.response.statusText
        });
    }
    return rejectWithValue({ message: err.message });
  }
});

export const updateUser = createAsyncThunk("user/updateUser", async (data) => {
    try {
      const response = await axios.put(`${userAPIUrl}/auth/user/update-user`, data.values, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${data?.token}`,
        },
        }
      );
      const { status, message } = response.data;
      return {status, message};
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
        throw { message: data.message || `Server error: ${status}`, status };
      } else {
        throw { message: "Network error or request failed.", status: 500 };
      }
    }
  }
);

export const fetchUserById = createAsyncThunk("/get-user/", async (userId) => {
  try {
    const response = await axios.get(`${userAPIUrl}/auth/user/${userId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const fetchUserByEmail = createAsyncThunk("/get-user-email/", async (email) => {
  try {
    const response = await axios.get(`${userAPIUrl}/auth/useremail/${email}`);
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const updateUserPayment = createAsyncThunk("user/updatepay", async (data) => {
  try{
    const response = await axios.put(`${userAPIUrl}/auth/user/update-payment`, data.values, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${data?.token}`,
      },
    }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
});

const userSlice = createSlice({
  name: "user",
  initialState: {
    data: null,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(addUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchUserById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUserById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchUserById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default userSlice.reducer;

// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from "axios";

// const userAPIUrl = process.env.REACT_APP_USER_API_URL;

// export const addUser = createAsyncThunk("user/AddUser", async (userData) => {
//   userData["status"] = 1;
//   try {
//     const response = await axios.post(
//       `http://localhost:3000/v1/api/user/signup`,
//       userData,
//       {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       }
//     );
//     return response.data;
//   } catch (error) {
//     throw error;
//   }
// });

// export const updateUser = createAsyncThunk(
//   "user/updateUser",
//   async (userData) => {
//     try {
//       const response = await axios.put(
//         `${userAPIUrl}/update-user/${userData.id}`,
//         userData.data,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
//           },
//         }
//       );
//       return response.data;
//     } catch (error) {
//       throw error;
//     }
//   }
// );

// export const fetchUserById = createAsyncThunk("/get-user/", async (userId) => {
//   try {
//     const response = await axios.get(`${userAPIUrl}/user/${userId}`);
//     return response.data;
//   } catch (error) {
//     throw error;
//   }
// });

// const userSlice = createSlice({
//   name: "user",
//   initialState: {
//     data: null,
//     isLoading: false,
//     error: null,
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(addUser.pending, (state) => {
//         state.isLoading = true;
//       })
//       .addCase(addUser.fulfilled, (state, action) => {
//         state.isLoading = false;
//         state.data = action.payload;
//         state.error = null;
//       })
//       .addCase(addUser.rejected, (state, action) => {
//         state.isLoading = false;
//         state.error = action.error.message;
//       })
//       .addCase(updateUser.pending, (state) => {
//         state.isLoading = true;
//       })
//       .addCase(updateUser.fulfilled, (state, action) => {
//         state.isLoading = false;
//         state.data = action.payload;
//         state.error = null;
//       })
//       .addCase(updateUser.rejected, (state, action) => {
//         state.isLoading = false;
//         state.error = action.error.message;
//       })
//       .addCase(fetchUserById.pending, (state) => {
//         state.isLoading = true;
//       })
//       .addCase(fetchUserById.fulfilled, (state, action) => {
//         state.isLoading = false;
//         state.data = action.payload;
//         state.error = null;
//       })
//       .addCase(fetchUserById.rejected, (state, action) => {
//         state.isLoading = false;
//         state.error = action.error.message;
//       });
//   },
// });

// export default userSlice.reducer;
