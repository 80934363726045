import { Route, Routes, Navigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import TradeLog from "../component/tradeLog";
import Header from "../component/header/header";
import { Container } from "react-bootstrap";
import Home from "../component/home";
import Strategies from "../component/strategies/Strategies";
import TradingAccounts from "../component/tradingAccounts/TradingAccounts";
import TradeAnalytics from "../component/TradingAnalytics";
import Tools from "../component/tools";
import Calendar from "../component/calendar";
import Calculator from "../component/calculator";
import Mantra from "../component/mantra";
import Userinfo from "../component/userinfo/userinfo";
import NotFound from "../component/404Page/NotFound";
import EditProfile from "../component/Edit Profile/editProfile";
import Popup from "../component/Popup/Popup";

const DashboardRouter = ({ state }) => {
  const [showPopup, setShowPopup] = useState(true);
  const user = useSelector((state) => state?.auth?.user);
  const createdAtDate = new Date(user.createdAt);
  const expiryDate = new Date(createdAtDate.getTime() + 14 * 24 * 60 * 60 * 1000);
  const expired = expiryDate < new Date();

  useEffect(() => {
    if(!expired){
    const timer = setTimeout(() => {
      setShowPopup(false);
    }, 10000); 

    return () => clearTimeout(timer);
  }
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div
      className={`main-content-wrap ${
        state?.collapsed ? "is-open" : "is-close"
      }`}
    >
      <Container fluid className="content p-0">
        <Header />
        {showPopup && (user.plan === null || user.plan === "") && !expired && (
          <Popup onClose={handleClosePopup} expired={expired}/>
        )}
        {showPopup && (user.plan === null || user.plan === "") && expired && (
          <Popup onClose={handleClosePopup} expired={expired}/>
        )}
        <Routes>
          <Route
            path="/tradelog/:starttDate?/:enddDate?"
            element={<TradeLog />}
          />
          <Route path="/strategies" element={<Strategies />} />
          <Route path="/trading-accounts" element={<TradingAccounts />} />
          <Route path="/trader-analytics/*" element={<TradeAnalytics />} />
          <Route path="/tools/*" element={<Tools />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/calculator/*" element={<Calculator />} />
          <Route path="/mantra" element={<Mantra />} />
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="/dashboard" element={<Home />} />
          <Route path="/editProfile" element={<EditProfile />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Container>
    </div>
  );
};

export default DashboardRouter;
